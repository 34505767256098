import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const legal = () => (
  <Layout>
    <SEO title="Fabien Naté - Mentions Légales" />
    <h3 className='underline-title'>Mentions Légales</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p className="properly-aligned">
      <span className="black-bold underline-title">Conditions d'utilisation</span><br/>
      Si vous visiter ce site web, vous acceptez de vous plier aux termes d'utilisation et aux directives du site.
      Veuillez les lire très attentivement. Cependant ces conditions d'utilisation sont dynamiques et peuvent 
      être changées en tout temps et sans préavis et vous devrez vérifier ces directives fréquemment. Toute personne 
      qui utilise ce Site web accepte les termes et les conditions d’utilisation en accédant au Site. 
      <br/><br/>
      La reproduction et la distribution des poèmes sont autorisées à des fins non-commerciales et à la seule 
      condition que la source y soit indiquée. Le site vous accorde un permis limité, révocable et non exclusif d’accès.
      <br/><br/>
      Ce site ou toute portion de ce site ne pourra être reproduite, copié, dupliqué, vendu, visité ou 
      autrement exploité pour des fins commerciales sans l’approbation écrite et expresse de l’opérateur. 
      Le code source ayant servi à la conception du site web est également visé par cette clause. L'ensemble
      de site est protegé par les lois nationales et internationales sur les droits d'auteur. 
      <br/><br/>
      Les lois applicables aux conditions d’utilisation du présent site seront celles du Canada.
      Ainsi les tribunaux compétents, pour discuter de tout litige afférent à l’utilisation du site internet, seront dans le 
      district judiciaire de Toronto, province de l'Ontario, Canada.
      </p>
      <br/>
      <p className="properly-aligned">
      <span className="black-bold underline-title">Politique de Confidentialité</span><br/>
      Ce site se préoccupe de la protection de la vie privée sur le net et s'engage à respecter la confidentialité
      des renseignements personnels qui sont collectés et à les protèger.
      <br/><br/>
      Les renseignements personnels qui sont collectés, sont recueillis au travers de formulaires, de fichiers
      témoins et grâce à l'interaction établie entre vous et ce site web.
      <br/><br/>
      Le site ne vend pas ni ne loue vos renseignements personnels à quelque tiers que ce soit ni ne les échange
      avec quelque tiers que ce soit.
      </p>
    </div>
    <br />
    <br />
    <Link to="/">Biographie</Link>
  </Layout>
)

export default legal
